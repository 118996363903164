<template>
  <div
    :class="isError ? 'bg-danger border-0' : 'bg-light'"
    ref="el"
    class="toast"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div v-if="isError" class="d-flex">
      <div class="toast-body text-white">{{ message }}</div>
      <button
        type="button"
        class="btn-close btn-close-white me-2 m-auto"
        data-bs-dismiss="toast"
        aria-label="Close"
      ></button>
    </div>
    <div v-else>
      <div class="toast-header">
        <strong class="me-auto">Message</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'bootstrap';

export default {
  name: 'BootstrapToast',
  props: {
    title: {
      type: String,
      default: 'Message',
    },
    message: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toast: null,
    };
  },
  mounted() {
    const { el } = this.$refs;

    this.toast = new Toast(el, { autohide: false });
    this.toast.show();

    ['show', 'shown', 'hide', 'hidden'].forEach((e) => {
      el.addEventListener(`${e}.bs.toast`, () => {
        this.$emit(e);
      });
    });
  },
};
</script>
